<template>
  <div>
    <div
      style="height: calc(100vh - 145px); overflow-y: auto; overflow-x: hidden"
    >
      <Price v-if="isActiveTab('tab-price')" />
      <Assign v-if="isActiveTab('tab-assign')" />
    </div>
    <v-divider class="mt-2"></v-divider>
    <div
      class="pt-2 pb-4 pb-md-2 white-space-nowrap overflow-x-auto overflow-y-hidden"
    >
      <v-btn
        :color="isActiveTab('tab-price') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-price')"
      >
        Bảng giá
      </v-btn>
      <v-btn
        :color="isActiveTab('tab-assign') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-assign')"
      >
        Bản quyền
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {
    Price: () => import("@/components/license/Price"),
    Assign: () => import("@/components/license/Assign"),
  },
  data: () => ({
    isLoading: false,
    tab: "tab-price",
    items: [],
  }),
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    isActiveTab(tab) {
      return this.tab === tab;
    },
  },
};
</script>

<style scoped></style>
